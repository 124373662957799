import ListApps from "./ListApps"
import ListNews from "./ListNews"
import TopSideIconMenu from "./TopSideIconMenu"
export default ()=>{
    const [content_menu,setContentMenu] = React.useState("apps")
    return(
        <div className="top-menu-animate bg-white border-telkom w-full flex gap-2 text-indigo-800 rounded-br-lg" style={{height:'80vh'}}>
            <TopSideIconMenu/>
            <div className="flex flex-col gap-3 p-3 w-full">
                <div className="flex gap-2 text-xs bg-telkom text-white rounded w-full">
                    <div onClick={()=>setContentMenu('apps')} className={`p-2 rounded ${content_menu==='apps'?'bg-indigo-700':''} cursor-pointer`}>Applications</div>
                    <div onClick={()=>setContentMenu('news')} className={`p-2 rounded ${content_menu==='news'?'bg-indigo-700':''} cursor-pointer`}>News</div>
                </div>
                <div>
                    <ListApps menu={content_menu}/>
                    <ListNews menu={content_menu}/>
                </div>
            </div>
        </div>
    )
}