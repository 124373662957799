import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Heading6 } from "@components/Heading"
import { Heading3 } from "../../components/Heading"

export default ({menu})=>{
    if(menu!='news') return
    const [top_menu,setTopMenu] = React.useState(['News TiOC','News DSO','News DIT','News Telkom'])
    return <>
        <Heading3 color="text-telkom">News</Heading3>
        <div className="w-full grid grid-cols-1 gap-1 items-center">
        {top_menu.map((t_menu,i)=>{
            return <div key={i} className="flex flex-col gap-2 rounded w-full hover:bg-gray-100 p-2 cursor-pointer w-fit">
            <Heading6>{t_menu}</Heading6>
        </div>
        })}
        </div>
    </>
}