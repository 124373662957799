const Heading1=({children,text_align="",color="text-gray-600"})=>{
    return(<h1 className={`text-2xl w-full font-bold relative ${color} ${text_align}`}>{children}</h1>)
}
const Heading2=({children,text_align="",color="text-gray-600"})=>{
    return(<h2 className={`text-xl w-full font-bold relative ${color} ${text_align}`}>{children}</h2>)
}
const Heading3=({children,text_align="",color="text-gray-600"})=>{
    return(<h3 className={`text-lg w-full font-bold relative ${color} ${text_align}`}>{children}</h3>)
}
const Heading4=({children,text_align="",color="text-gray-600"})=>{
    return(<h4 className={`text-base w-full font-bold relative ${color} ${text_align}`}>{children}</h4>)
}
const Heading5=({children,text_align="",color="text-gray-600"})=>{
    return(<h5 className={`text-sm w-full font-bold relative ${color} ${text_align}`}>{children}</h5>)
}
const Heading6=({children,text_align="",color="text-gray-600"})=>{
    return(<h6 className={`text-xs w-full font-bold relative ${color} ${text_align}`}>{children}</h6>)
}
export {Heading1,Heading2,Heading3,Heading4,Heading5,Heading6}