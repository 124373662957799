import { CardBorder } from "../../components/Card"
import { Heading2 } from "../../components/Heading"
import { ButtonPrimary } from "../../components/Button"
import { AppContext } from "../../App"

export default ({close})=>{
    const {profile_active,setProfileActive} = React.useContext(AppContext)
    if(!profile_active) return 
    const [profile,setProfile] = React.useState({nik:10101,name:'Vembri Riyan',unit:'IT',position:'Programmer'})
    return(
        <div className="fixed top-0 left-0 right-0 z-[3000]">
            <div className="w-96 animate-popup top-16 mx-auto relative z-[3000]">
            <CardBorder>
                <div className="p-5">
                <Heading2 text_align="text-center">Profile</Heading2>
                <div className="mt-5 flex flex-col gap-3">
                    <div className="flex flex-col">
                        <div className="text-xs text-gray-600">NIK</div>
                        <div className="text-sm text-gray-700 border-b p-2 font-bold">{profile.nik}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-xs text-gray-600">Name</div>
                        <div className="text-sm text-gray-700 border-b p-2 font-bold">{profile.name}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-xs text-gray-600">Unit</div>
                        <div className="text-sm text-gray-700 border-b p-2 font-bold">{profile.unit}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-xs text-gray-600">Position</div>
                        <div className="text-sm text-gray-700 border-b p-2 font-bold">{profile.position}</div>
                    </div>
                </div>
                </div>
            </CardBorder>
            <div onClick={()=>setProfileActive(!profile_active)} className="mx-auto bg-telkom text-white border border-white w-fit rounded px-3 py-1 mt-2 cursor-pointer">Close</div>
            </div>
            <div onClick={()=>setProfileActive(!profile_active)} className='absolute top-0 left-0 bottom-0 right-0 bg-black opacity-60 h-screen w-full z-[2999]'></div>
        </div>
    )
}