import { faGlobe, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Heading6 } from "@components/Heading"
import { Heading3 } from "../../components/Heading"

export default ({menu})=>{
    if(menu!='apps') return
    const [top_menu,setTopMenu] = React.useState(['Monitoring & Controlling TiOC','Autoscheduler DM TiOC','WA Scheduler','IOC R/W','Digitozation DSO','About TiOC'])
    return <>
        <Heading3 color="text-telkom">Application</Heading3>
        <div className="flex items-center">
            <FontAwesomeIcon icon={faSearch} className="rounded-l text-telkom mx-2"/>
            <input className="rounded focus:border-indigo-600 text-telkom p-2 text-xs outline-none border w-full my-5" placeholder="Search Application"/>
        </div>
        <div className="w-full grid grid-cols-2 gap-2 items-center">
        {top_menu.map((t_menu,i)=>{
            return <div key={i} className="flex flex-col gap-2 justify-content-center rounded border w-full text-center p-2 cursor-pointer w-fit">
            <FontAwesomeIcon icon={faGlobe}/>
            <Heading6>{t_menu}</Heading6>
        </div>
        })}
        </div>
    </>
}