import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn, faSpinner } from "@fortawesome/free-solid-svg-icons";
import React,{ useState } from "react";
import BgSignIn from "@assets/img/bg.jpeg"
import Logo from "@assets/img/tioc.jpg"
import { useNavigate } from "react-router-dom";
const SignIn= ({ setRefresh }) => {
	document.title = "Portal Telkom TiOC - Sign In"
	const navigate = useNavigate()
	const [wrong_message,setWrongMessage] = React.useState("")
	const [process,setProcess] = React.useState(false)
	const [sign_in, setSignIn] = useState({ username: "", password: "" });
	function setUser(e) {
		sign_in.username = e.target.value;
	}

	function setPassword(e) {
		sign_in.password = e.target.value;
	}

	function SignIn() {
		setProcess(true)
		localStorage.setItem('portal',1)
		navigate('/')
		if(!sign_in.username){
			setWrongMessage("Username is required");
			setProcess(false)
		}else if(!sign_in.password){
			setWrongMessage("Password is required");
			setProcess(false)
		}else{
			api.signIn({
				data: sign_in,
				response: (res) => {
					if (res.data.status==200) {
						localStorage.setItem("dfa_sign_in_session", res.data.data.dfa_sign_in_session);	
						setWrongMessage("")
						navigate('application')
						setRefresh()
						setProcess(false)
					}else{
						setWrongMessage(res.data.message);
						setProcess(false)
					}
				},
			});
		}
	}
	React.useEffect(()=>{
		if(localStorage.getItem('portal')) return navigate('/')
		// if(localStorage.getItem('dfa_sign_in_session')) navigate('application')
	},[localStorage.getItem('portal')])

	document.addEventListener('keydown',e=>e.key=="Enter" && SignIn())
	

	// if(!localStorage.getItem('dfa_sign_in_session'))
	return (
		<div className="min-h-screen bg-telkom" style={{background:BgSignIn}}>
            <img src={BgSignIn} className="absolute opacity-20 max-h-screen object-fit w-full"/>
            <div className="relative pt-10">
                <div className="rounded-full bg-white overflow-hidden w-20 h-20 mx-auto flex items-center p-2 object-cover">
                    <img src={Logo} className=""/>
                </div>

                <div className="text-white text-lg text-center pt-5">Welcome to <span className="font-bold text-xl">Portal Telkom TiOC</span></div>

                <div className="p-5 drop-shadow-md bg-white rounded-lg w-full sm:w-full md:w-96 mx-auto mt-10">
                    <div className="text-gray-700 text-lg mb-2 font-bold text-center mb-5">Sign In</div>
                    {wrong_message!=="" && <div className="text-red-600 bg-red-100 rounded p-1 text-sm mb-2 text-center border border-red-600">{wrong_message}</div>}
                    <div className="flex flex-col gap-2 text-xs">
                        <input onChange={setUser} placeholder="Username" className={"rounded p-2 border text-xs outline-none"} defaultValue={sign_in.username} />
                        <input onChange={setPassword} type="password" placeholder="Password" className={"rounded p-2 border text-xs outline-none"}  defaultValue={sign_in.password} />
                    </div>
                    <div className="text-white text-xs rounded bg-telkom p-2 w-fit mt-5 mx-auto cursor-pointer" onClick={SignIn}>
                        {process && 
						<>
							<FontAwesomeIcon icon={faSpinner} className="animate-spin"/>
							<span className="ml-1">Sign In</span>
						</>
						||
							<>
							<FontAwesomeIcon icon={faSignIn} />
							<span className="ml-3">Sign In</span>
							</>
						}
						
						
                    </div>
                </div>
            </div>
		</div>
	);
};

export default SignIn;
