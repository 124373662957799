import { faCubes, faCubesStacked, faGear, faNewspaper, faUserAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Heading6 } from "@components/Heading"
import { Heading3 } from "../../components/Heading"
import { CardBorder } from "../../components/Card"
import profile from "@assets/img/sea.jpg"
import { PopUpConfirm } from "../../components/PopUp"
import { AppContext } from "../../App"
import { useContext } from "react"
import { NavLink } from "react-router-dom"

const MenuContainer=({children,onClick})=>(
    <div onClick={onClick} className="w-6 h-6 relative bg-indigo-800 text-white rounded-full p-2 flex items-center justify-center cursor-pointer">
        {children}
    </div>
)
const Settings=({switchMenu,menu_active})=>{
    return(
        <MenuContainer onClick={switchMenu}>
            <FontAwesomeIcon icon={faGear}/>
            {menu_active==='settings' && <div className="absolute left-7 bottom-0 w-36 z-[4]">
                <CardBorder>
                    <div className="flex flex-col gap-2 text-gray-700">
                        <NavLink to={'/setting_apps'} className="p-1 hover:text-telkom hover:bg-gray-100 rounded">Setting Apps</NavLink>
                        <div className="p-1 hover:text-telkom hover:bg-gray-100 rounded">Setting News</div>
                    </div>
                </CardBorder>
            </div>}
        </MenuContainer>
    )
}
const AppsManagement=({switchMenu,menu_active})=>{
    return(
        <MenuContainer onClick={switchMenu}>
            <NavLink to={'/apps_management'} title="Applications Management"><FontAwesomeIcon icon={faCubes}/></NavLink>
        </MenuContainer>
    )
}
const News=({switchMenu,menu_active})=>{
    return(
        <MenuContainer onClick={switchMenu}>
            <NavLink to={'/news_management'} title="News Management"><FontAwesomeIcon icon={faNewspaper} clas/></NavLink>
        </MenuContainer>
    )
}
const User=({switchMenu,menu_active})=>{
    const [menu,setMenu] = React.useState("")
    const {setSignOut,sign_out}  = useContext(AppContext)
    function switchMenuUser(menu){
        menu!==menu_active ? setMenu(menu) : setMenu("")
    }
    return(
        <MenuContainer onClick={switchMenu}>
            <FontAwesomeIcon icon={faUserAlt}/>
            {menu_active==='user' && <div className="absolute left-7 bottom-0 w-36 z-[4]">
                <CardBorder>
                    <div className="flex flex-col gap-2 text-gray-700">
                        <div className="w-20 h-20 rounded-full bg-gray-100 overflow-hidden object-cover mx-auto">
                            <img src={profile} className="min-w-full min-h-full"/>
                        </div>
                        <Heading6 text_align="text-center">Vembri Riyan</Heading6>
                        <hr/>
                        <div className="p-1 hover:text-telkom hover:bg-gray-100 rounded">Profile</div>
                        <div onClick={()=>setSignOut(!sign_out)} className="p-1 hover:text-telkom hover:bg-gray-100 rounded">Sign Out</div>
                    </div>
                </CardBorder>
            </div>}
            
        </MenuContainer>
    )
}
export default ()=>{
    const [menu_active,setMenuActive] = React.useState("")
    function switchMenu(menu){
        menu!==menu_active ? setMenuActive(menu) : setMenuActive("")
    }

    return <div className="flex flex-col gap-2 justify-end place-items-end h-full text-xs p-3 border-r-2 border-telkom">
        {/* <User menu_active={menu_active} switchMenu={()=>switchMenu('user')}/> */}
        {/* <Settings menu_active={menu_active} switchMenu={()=>switchMenu('settings')}/> */}
        <AppsManagement/>
        <News/>
    </div>
}