import './App.css'
import { BrowserRouter,Route,Routes, useNavigate } from 'react-router-dom';
import Portal from "@main"
import SignIn from '@main/SignIn';
import { PopUpConfirm } from './components/PopUp';
import Profile from './main/popup_page/Profile';
import ManagementNews from '@management_news'
import SettingApps from '@setting_apps'
import AddApplication from '@setting_apps/AddApplication';
import WriteNews from '@management_news/WriteNews';

const AppContext = React.createContext()
function App() {
  const [refresh,setRefresh] = React.useState(false)
  const [sign_out,setSignOut] = React.useState(false)
  const [profile_active,setProfileActive] = React.useState(false)
  return (
    <AppContext.Provider value={{setSignOut,sign_out,profile_active,setProfileActive}}>
    <div className='text-gray-600 bg-white text-sm min-h-screen'>
      <BrowserRouter basename='/'>
        <Routes>
          <Route path='/' element={<Portal/>}/>
          <Route path='/sign_in' element={<SignIn/>}/>
          <Route path='/apps_management' element={<SettingApps/>}/>
          <Route path='/news_management' element={<ManagementNews/>}/>
          <Route path='/add_application' element={<AddApplication/>}/>
          <Route path='/write_news' element={<WriteNews/>}/>
        </Routes>
      </BrowserRouter>
      <PopUpConfirm text="Are you sure want to Sign Out?" action={()=>{localStorage.removeItem('portal');setSignOut(!sign_out)}} show={sign_out} yes="Yes" no="Cancel" close={()=>setSignOut(!sign_out)}/>
      <Profile/>
    </div>
    </AppContext.Provider>
  );
}
export {AppContext}
export default App;
