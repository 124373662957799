import TopMenu from "@menu/TopMenu"
import { Heading1, Heading2, Heading3 } from "../components/Heading"
import background from "@assets/img/bg.jpeg"
import profile from "@assets/img/sea.jpg"
import logo_telkom from "@assets/img/telkom.png"
import logo_tioc from "@assets/img/tioc.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe, faNewspaper } from "@fortawesome/free-solid-svg-icons"
import { PopUpConfirm } from "../components/PopUp"
import { TypeAnimation } from "react-type-animation"
import { useNavigate } from "react-router"

const PreviewApp=()=>{
    return(
        <div className="grid grid-flow-col auto-cols-auto overflow-x-scroll gap-2 mt-5" style={{scrollbarWidth: 'none'}}>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={'https://serikatnews.com/wp-content/uploads/2019/09/telkom.jpg'} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={'https://the-iconomics.storage.googleapis.com/wp-content/uploads/2020/03/30104359/Telkom-Indoneia.jpg'} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={'https://static.promediateknologi.id/crop/0x0:0x0/750x500/webp/photo/p1/910/2023/09/19/FotoJet-2023-09-19T120223271-440420183.jpg'} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={'https://static.promediateknologi.id/crop/0x0:0x0/750x500/webp/photo/p1/910/2023/09/19/FotoJet-2023-09-19T120223271-440420183.jpg'} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={profile} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={profile} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={profile} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={profile} className="min-w-full min-h-full"/>
            </div>
            <div className="bg-gray-100 h-40 w-80 rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                <img src={profile} className="min-w-full min-h-full"/>
            </div>
        </div>
    
    )
}
export default ()=>{
    const navigate = useNavigate()
    const [news,setNews] = React.useState(['News TiOC','News DSO','News DIT','News Telkom','News DIT','News Telkom','News DIT','News Telkom','News DIT','News Telkom','News DIT','News Telkom','News DIT','News Telkom','News DIT','News Telkom'])
    React.useEffect(()=>{
        if(!localStorage.getItem('portal')) return navigate('/sign_in')
    },[localStorage.getItem('portal')])

    if(localStorage.getItem('portal'))
    return(
        <div>
            <img src={background} className="h-full w-full object-cover absolute"/>
            <div className="min-h-screen w-full opacity-80 bg-telkom absolute"></div>
            <TopMenu/>
            <div className="pt-20 pb-5 flex items-center justify-center gap-4">
                <div className="object-cover w-16 h-16 overflow-hidden rounded-full bg-white relative p-2 flex items-center">
                    <img src={logo_telkom} className="min-w-full"/>
                </div>
                <div>
                    <Heading1 text_align="text-center" color="text-white">Portal Telkom TiOC</Heading1>
                    <div className="text-center text-white relative">Welcome <span className="font-bold">Vembri Riyan</span> to Portal TiOC</div>
                </div>
                <div className="object-cover w-16 h-16 overflow-hidden rounded-full bg-white relative p-2 flex items-center">
                    <img src={logo_tioc} className="min-w-full"/>
                </div>
            </div>
            <div className="relative text-xs text-white text-center mb-4">
                    <TypeAnimation
                        sequence={[
                            'The World in Your Hand',
                            2000,
                            'Selalu ada inovasi untuk indonesia',
                            2000,
                            'Kami menghadirkan dunia dalam genggaman',
                            2000
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ fontSize: '2em', display: 'inline-block' }}
                        repeat={Infinity}
                        />
            </div>
            <div className="relative mx-5 overflow-auto rounded bg-white p-5" style={{height:'70vh',scrollbarWidth: 'none'}}>
                <Heading2>News</Heading2>
                <PreviewApp/>                
                <div className="grid grid-cols-4 gap-3 p-5 mt-5">
                    <div>
                        <div className="bg-gray-100 h-60 w-full rounded-lg bg-gray-100 overflow-hidden object-cover flex-1">
                            <img src={'https://www.pajak.com/storage/2023/09/digitalisasi-telkom-indonesia-HpONv8PGln.jpg'} className="min-w-full min-h-full"/>
                        </div>
                    </div>
                    <div className="col-span-3">
                        <Heading2>Telkom</Heading2>
                        <p>Aliquip veniam non ex consectetur laboris qui laborum do amet elit commodo exercitation et culpa. Esse eu ut ullamco ut magna voluptate reprehenderit. Sunt voluptate in do esse occaecat ea ad. Sunt officia exercitation incididunt sint excepteur esse laborum.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}