import { faChevronLeft, faEdit, faGlobe, faNewspaper, faPen, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Heading4,Heading6 } from "@components/Heading"
import { NavLink } from "react-router-dom"
import { PopUpConfirm } from "../../../components/PopUp"

export default ()=>{
    document.title = 'Portal Telkom TiOC - News Management'
    const [isDelete,setIsDelete] = React.useState(false)
    const [apps,setApps] = React.useState(['Telkom Pintar','Telkom AI','Telkom Future'])
    return(
        <div>
            <div className="flex gap-5 items-center">
                <NavLink to={'/'} className="text-white flex items-center gap-2 bg-telkom rounded p-2 w-fit text-xs m-2">
                    <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                    <span>Back</span>
                </NavLink>
                <Heading4 color="text-telkom w-full">News Management</Heading4>
                <NavLink to={'/write_news'} className="text-white flex whitespace-nowrap items-center gap-2 bg-green-700 rounded p-2 w-fit text-xs m-2">
                    <FontAwesomeIcon icon={faPen} className="text-sm"/>
                    <span>Write News</span>
                </NavLink>
            </div>
            <div className="flex items-center mx-5">
                <FontAwesomeIcon icon={faSearch} className="rounded-l text-telkom mx-2"/>
                <input className="rounded focus:border-indigo-600 p-2 text-xs outline-none border w-full my-5" placeholder="Search News"/>
            </div>
            <div className="w-full grid gap-2 items-center p-5">
            {apps.map((t_menu,i)=>{
                return(
                    <div key={i} className="flex gap-2 rounded-lg items-center border bg-white hover:bg-indigo-600 hover:text-white w-full p-3 cursor-pointer w-fit">
                        <div className="flex flex-col w-full gap-1">
                            <div className="flex gap-2 w-full">
                                <FontAwesomeIcon icon={faNewspaper} className="text-2xl"/>
                                <div className="text-sm font-bold">{t_menu}</div>
                            </div>
                            <div className="grid grid-cols-2 w-fit">
                                <div className="text-xs">Penulis</div>
                                <div className="text-xs">: <span className="font-bold">Vembri Riyan</span></div>
                                <div className="text-xs">Modify at</div>
                                <div className="text-xs">: 2024-01-23 12:00 AM</div>
                            </div>
                        </div>
                        <div className="flex gap-4 px-5">
                            <FontAwesomeIcon icon={faEdit} className="text-blue-500 p-2 bg-white rounded"/>
                            <FontAwesomeIcon onClick={()=>setIsDelete(!false)} icon={faTrashAlt} className="text-red-500 p-2 bg-white rounded"/>
                        </div>
                    </div>
                    )
            })}
            </div>
            <PopUpConfirm text="Delete Application?" show={isDelete} close={()=>setIsDelete(false)}/>
        </div>
    )
}