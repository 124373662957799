import { NavLink } from "react-router-dom"
import { Heading3,Heading4 } from "../../../components/Heading"
import { faChevronLeft, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import favicon from "@src/favicon.ico"
export default ()=>{
    document.title = 'Portal Telkom TiOC - Add News'
    const [new_app,setNewApp] =React.useState({title:"",content:""})
    function setValue(e,field){
        setNewApp({...new_app,[field]:e.target.value})
    }
    
    return(
        <div>
            <div className="flex gap-5 items-center">
                <NavLink to={'/news_management'} className="text-white flex items-center gap-2 bg-telkom rounded p-2 w-fit text-xs m-2">
                    <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                    <span>Back</span>
                </NavLink>
                <Heading4 color="text-telkom w-full">Write News</Heading4>
            </div>
            <div className="flex flex-col gap-3 p-5">
                <div className="flex flex-col">
                    <div className="text-xs text-gray-600">Title</div>
                    <input maxLength={100} className="text-sm text-gray-700 border-b p-2 focus:border-indigo-600 font-bold outline-none" onChange={e=>setValue(e,'title')} placeholder="Title"/>
                    <div className="text-xs text-right">{new_app.title.length}/100</div>
                </div>
                <div className="flex flex-col">
                    <div className="text-xs text-gray-600">Content</div>
                    <textarea rows={10} spellCheck="false" onChange={e=>setValue(e,'content')} maxLength={2000} className="text-sm resize-none text-gray-700 border-b p-2 focus:border-indigo-600 font-bold outline-none" placeholder="Content"/>
                    <div className="text-xs text-right">{new_app.content.length}/2000</div>
                </div>
                <div className="text-white bg-telkom rounded px-5 text-xs py-2 w-fit mx-auto"><FontAwesomeIcon icon={faSave}/><span className="ml-2">Save</span></div>
            </div>

        </div>
    )
}