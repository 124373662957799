import { faChevronLeft, faEdit, faGlobe, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Heading4,Heading6 } from "@components/Heading"
import { NavLink } from "react-router-dom"
import { PopUpConfirm } from "../../../components/PopUp"

export default ()=>{
    document.title = 'Portal Telkom TiOC - Applications Management'
    const [isDelete,setIsDelete] = React.useState(false)
    const [apps,setApps] = React.useState(['Monitoring & Controlling TiOC','Autoscheduler DM TiOC','WA Scheduler','IOC R/W','Digitozation DSO','About TiOC'])
    return(
        <div>
            <div className="flex gap-5 items-center">
                <NavLink to={'/'} className="text-white flex items-center gap-2 bg-telkom rounded p-2 w-fit text-xs m-2">
                    <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                    <span>Back</span>
                </NavLink>
                <Heading4 color="text-telkom w-full">Applications Management</Heading4>
                <NavLink to={'/add_application'} className="text-white flex whitespace-nowrap items-center gap-2 bg-green-700 rounded p-2 w-fit text-xs m-2">
                    <FontAwesomeIcon icon={faPlus} className="text-lg"/>
                    <span>Add Application</span>
                </NavLink>
            </div>
            <div className="flex items-center mx-5">
                <FontAwesomeIcon icon={faSearch} className="rounded-l text-telkom mx-2"/>
                <input className="rounded focus:border-indigo-600 p-2 text-xs outline-none border w-full my-5" placeholder="Search Application"/>
            </div>
            <div className="w-full grid gap-2 items-center p-2">
            {apps.map((t_menu,i)=>{
                return(
                    <div key={i} className="flex gap-2 rounded border w-full p-3 cursor-pointer w-fit">
                        <div className="flex gap-2 w-full">
                            <FontAwesomeIcon icon={faGlobe}/>
                            <Heading6>{t_menu}</Heading6>
                        </div>
                        <div className="flex gap-4 px-5">
                            <FontAwesomeIcon icon={faEdit} className="text-blue-500"/>
                            <FontAwesomeIcon onClick={()=>setIsDelete(!false)} icon={faTrashAlt} className="text-red-500"/>
                        </div>
                    </div>
                    )
            })}
            </div>
            <PopUpConfirm text="Delete Application?" show={isDelete} close={()=>setIsDelete(false)}/>
        </div>
    )
}