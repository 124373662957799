const PopUpConfirm=({action=()=>{},close=()=>{},text="Are you sure?",show=false,yes="Yes",no="Cancel"})=>{
    React.useEffect(()=>{
        document.addEventListener('keyup',(e)=>{
            if(e.key=="Enter") action()
        })
    },[])
    if(show)
    return(
        <>
        <div className='rounded p-2 bg-white fixed mx-auto w-fit top-20 border left-0 right-0 text-xs text-gray-700 z-[3000]'>
            <div className='flex flex-col w-60'>
                <span className='text-center text-sm py-2 mb-2'>{text}</span>
                <div className='columns-2 text-center'>
                    <div onClick={close} className='rounded p-2 bg-gray-600 text-white cursor-pointer'>{no}</div>
                    <div onClick={action} className='rounded p-2 bg-red-600 text-white cursor-pointer'>{yes}</div>
                </div>
            </div>
        </div>
        <div className='fixed top-0 left-0 bg-black opacity-60 h-full w-full z-[2999]' onClick={close}></div>
        </>
    )
}
export {PopUpConfirm}