import { NavLink } from "react-router-dom"
import { Heading3,Heading4 } from "../../../components/Heading"
import { faChevronLeft, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import favicon from "@src/favicon.ico"
export default ()=>{
    document.title = 'Portal Telkom TiOC - Add Application'
    const [new_app,setNewApp] =React.useState({app_name:"",link:"",short_description:"",description:""})
    function setValue(e,field){
        setNewApp({...new_app,[field]:e.target.value})
    }
    
    return(
        <div>
            <div className="flex gap-5 items-center">
                <NavLink to={'/apps_management'} className="text-white flex items-center gap-2 bg-telkom rounded p-2 w-fit text-xs m-2">
                    <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                    <span>Back</span>
                </NavLink>
                <Heading4 color="text-telkom w-full">Add Application</Heading4>
            </div>
            <div className="flex flex-col gap-3 p-5">
                <div className="flex flex-col">
                    <div className="text-xs text-gray-600">Application Name</div>
                    <input maxLength={100} className="text-sm text-gray-700 border-b p-2 focus:border-indigo-600 font-bold outline-none" onChange={e=>setValue(e,'app_name')} placeholder="Application Name"/>
                    <div className="text-xs text-right">{new_app.app_name.length}/100</div>
                </div>
                <div className="flex flex-col">
                    <div className="text-xs text-gray-600">Link</div>
                    <input className="text-sm text-gray-700 border-b p-2 focus:border-indigo-600 font-bold outline-none" onChange={e=>setValue(e,'link')} placeholder="Link"/>
                    <div className="flex gap-3 mt-3 ml-3">
                        <div className="text-xs text-gray-600">Target Link</div>
                        
                        <div className="flex gap-2">
                            <input type="radio" name="target_link" className="text-sm text-gray-700 border-b p-2 font-bold outline-none" onChange={e=>setValue(e,'link')} placeholder="Link"/>
                            <label>Blank Page</label>
                        </div>
                        <div className="flex gap-2">
                            <input type="radio" name="target_link" className="text-sm text-gray-700 border-b p-2 font-bold outline-none" onChange={e=>setValue(e,'link')} placeholder="Link"/>
                            <label>On Portal</label>
                        </div>
                        
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="text-xs text-gray-600">Short Description</div>
                    <textarea spellCheck="false" onChange={e=>setValue(e,'short_description')} maxLength={200} className="text-sm resize-none text-gray-700 border-b p-2 focus:border-indigo-600 font-bold outline-none" placeholder="Short Description"/>
                    <div className="text-xs text-right">{new_app.short_description.length}/200</div>
                </div>
                <div className="flex flex-col">
                    <div className="text-xs text-gray-600">Description</div>
                    <textarea spellCheck="false" onChange={e=>setValue(e,'description')} maxLength={2000} className="text-sm resize-none text-gray-700 border-b p-2 focus:border-indigo-600 font-bold outline-none" placeholder="Description"/>
                    <div className="text-xs text-right">{new_app.description.length}/2000</div>
                </div>
                <div className="text-white bg-telkom rounded px-5 text-xs py-2 w-fit mx-auto"><FontAwesomeIcon icon={faSave}/><span className="ml-2">Save</span></div>
            </div>

        </div>
    )
}