import { faChevronDown, faChevronUp, faGlobe, faUser, faUserAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ContainerTopMenu from "./ContainerTopMenu"
import { Heading6 } from "../../components/Heading"
import profile from "@assets/img/sea.jpg"
import { AppContext } from "../../App"
import { CardBorder } from "@components/Card"
const User=({portal_menu_active})=>{
    if(portal_menu_active) return
    const [menu,setMenu] = React.useState("")
    const [active,setActive] = React.useState(false)
    const {setSignOut,sign_out,profile_active,setProfileActive}  = React.useContext(AppContext)
    
    return(
        <div className="relative mr-5">
            <div onClick={()=>setActive(!active)} className="rounded-b-lg border border-white bg-white text-telkom flex items-center px-3 py-1 gap-3 cursor-pointer">
                {/* <FontAwesomeIcon icon={faUserCircle} className="text-lg text-indigo-700"/> */}
                <div className="w-8 h-8 rounded-full bg-gray-100 overflow-hidden object-cover mx-auto">
                    <img src={profile} className="min-w-full min-h-full"/>
                </div>
                <div className="flex-1 whitespace-nowrap">
                    <div className="text-sm font-bold">Vembri Riyan</div>
                    <div className="text-xs text-gray-700">Programmer</div>
                </div>
            </div>
            {active && <div className="absolute right-0 top-12 w-36 z-[4]">
                <CardBorder>
                    <div className="flex flex-col gap-2 text-gray-700">
                        <div className="w-20 h-20 rounded-full bg-gray-100 overflow-hidden object-cover mx-auto">
                            <img src={profile} className="min-w-full min-h-full"/>
                        </div>
                        <Heading6 text_align="text-center">Vembri Riyan</Heading6>
                        <hr/>
                        <div onClick={()=>{setProfileActive(!profile_active);setActive(!active)}} className="p-1 hover:text-telkom hover:bg-gray-100 rounded cursor-pointer">Profile</div>
                        <div onClick={()=>{setSignOut(!sign_out);setActive(!active)}} className="p-1 hover:text-telkom hover:bg-gray-100 rounded cursor-pointer">Sign Out</div>
                    </div>
                </CardBorder>
            </div>}
            
        </div>
    )
}
export default ()=>{
    const [is_active_top_menu,setTopMenuActive] = React.useState(false)
    const [top_menu,setTopMenu] = React.useState(['Monitoring & Controlling TiOC','Autoscheduler DM TiOC','WA Scheduler','IOC R/W','Digitozation DSO','About TiOC','Monitoring & Controlling TiOC','Autoscheduler DM TiOC','WA Scheduler','IOC R/W','Digitozation DSO','About TiOC'])
    return(
        <div className="fixed top-0 left-0 right-0 z-[200]">
            {is_active_top_menu && <div onClick={()=>setTopMenuActive(!is_active_top_menu)} className="min-h-screen w-full opacity-20 bg-gray-800 absolute"></div>}
            <div className="flex w-1/2 z-[2] relative">
                {is_active_top_menu && <ContainerTopMenu/>}
            </div>
            <div className="flex gap-2">
                <div onClick={()=>setTopMenuActive(!is_active_top_menu)} className="relative whitespace-nowrap z-[2] rounded-b-lg ml-5 cursor-pointer p-3 bg-telkom text-white flex items-center gap-2 w-fit border-b border-x border-white">
                    <div className="flex items-center w-4 h-4 text-xs bg-white rounded-full justify-center animate-bounce">
                        <FontAwesomeIcon icon={is_active_top_menu?faChevronUp:faChevronDown} className="text-telkom"/>
                    </div>
                    <span className="text-xs font-bold">Portal Menu</span>
                </div>
                {!is_active_top_menu && <div className="rounded-full p-1 px-3 text-xs bg-white flex items-center gap-3 w-full whitespace-nowrap overflow-x-scroll" style={{scrollbarWidth:'none'}}>
                    {top_menu.map((menu,i)=>(
                        <a href="https://www.telkom.co.id/sites" target="_blank" key={i} className="px-3 py-2 flex-1 cursor-pointer font-bold border hover:border-indigo-800 hover:bg-indigo-700 hover:text-white rounded-full text-gray-600"><FontAwesomeIcon icon={faGlobe} className="mr-2"/>{menu}</a>
                        ))}
                </div>}
                <User portal_menu_active={is_active_top_menu}/>
            </div>
        </div>
    )
}